import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ReturnMaterialsService } from 'src/app/core/services/return-materials.service';

@Component({
  selector: 'app-ka-return-material-notification',
  templateUrl: './ka-return-material-notification.component.html',
  styleUrls: ['./ka-return-material-notification.component.scss'],
})
export class KaReturnMaterialNotificationComponent {
  public notificationObs: Observable<boolean>;
  public readonly ROOT = 'NEW_ORDER.RETURN_NOTIFICATION.TEXT';
  public close = false;

  constructor(private returnMatServ: ReturnMaterialsService) {
    this.notificationObs = this.returnMatServ.getSharingNotifications();
    this.notificationObs.subscribe(value => this.close=false);
  }

  closeNotification(){
    this.close = true;
  }
}
