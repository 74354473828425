import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethod } from 'src/app/core/models/payment-method.model';
import { SegmentInformation } from 'src/app/pages/new-order/steps/s6-mini-credit/components/how-to-pay-by-category/how-to-pay-by-category.component';

@Pipe({
  name: 'showPaymentMethodWithCreditBalance',
})
export class ShowPaymentMethodWithCreditBalancePipe implements PipeTransform {
  transform(
    paymentMethod: PaymentMethod,
    segmentInformation: SegmentInformation,
    paymentMethodsColection,
  ): boolean {
    const result =
      paymentMethod?.showCreditBalance &&
      segmentInformation.creditInfo.available !== 0;
    if (!result && !paymentMethod?.showCreditBalance) {
      //Por lo menos debe existir 1 payment Method por credito que tenga saldo disponible para ocultar el efectivo.

      //Por lo menos hay un payment method
      const creditPaymentMethod = paymentMethodsColection.find(
        (pm) =>
          paymentMethod.erpPaymentMethodId !== pm.erpPaymentMethodId &&
          segmentInformation?.creditInfo.available >=
            segmentInformation?.creditInfo.spent &&segmentInformation.creditInfo.status === 'OK',
      );
      return creditPaymentMethod ? false : true;
    }
    return result;
  }
}
