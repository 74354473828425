import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { ParserUtils } from 'src/app/core/utils/parser-utils';
import { environment } from 'src/environments/environment';
import { BERespModel } from '../models/backend/BE-response.model';
import { Client } from '../models/client.model';
import { LoginUser } from '../models/login-user.model';
import { UserLocal } from '../models/user-local.model';
import { ApiService } from './api.service';
import { EncrDecrService } from './encr-decr.service';
import { logout } from '../state/actions/user.actions';
import * as UserActions from 'src/app/core/state/actions/user.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfo } from '../models/user-info.model';

import { CountryCodes } from '../enums/country-codes.enum';
import { AppUtils } from '../utils/app-utils';
@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  readonly EndpointsCodes = EndpointsCodes;
  client: Client;
  userLocal: UserLocal;
  user: UserInfo;
  userId: string;
  private subscriptions = new Subscription();
  get user$(): Observable<UserInfo> {
    return this.store.select('user');
  }

  constructor(
    private apiSrv: ApiService, 
    private encrDecrService: EncrDecrService, 
    private store: Store<{ client: Client; userLocal: UserLocal, user: UserInfo }>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){
    this.store.select('client').subscribe((client) => (this.client = client));
    this.store.select('user').subscribe((user) => (this.user = user));
    this.store.select('userLocal').subscribe((userLocal) => (this.userLocal = userLocal));
  }

  getUserInfo(username: string): Observable<BERespModel> {
    const user = username ? username : this.user.username;
    return new Observable((obs) => {
      this.apiSrv.get('users/' + user, EndpointsCodes.GET_USER_INFO, { showError: false }).subscribe(
        (res) => {
          if (!res?.data?.userId) obs.error({ code: 'UserLambdaValidationException' });
          res?.data?.clients?.forEach((client) => (client['hasLockOrder'] = client?.locks?.some((lock) => lock.lockOrder)));
          res?.data?.clients?.sort((client, clientB) => {
            if (client['hasLockOrder']) return 1;
            return -1;
          });
          res?.data?.clients?.sort((a: Client, b: Client) => a.street.localeCompare(b.street));
          obs.next(res);
        },
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  setUserInfoDevice(username: string, device: string, devicePlatform: string, showError: boolean): Observable<BERespModel> {
    const requestObj = { deviceId: device, platform: devicePlatform };
    return new Observable((obs) => {
      this.apiSrv.post('users/' + username + '/device', EndpointsCodes.POST_USER_DEVICE_INFO, requestObj, { showError }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  initClientSession(): Observable<BERespModel> {
    return new Observable((obs) => {
      this.apiSrv.get(`clients/${this.client.clientId}/initclientsession`, EndpointsCodes.INIT_CLIENT_SESSION, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  changeUserPassword(username: string, code: string, password: string, showError: boolean): Observable<BERespModel> {
    const requestObj = {
      username: this.encrDecrService.encrypt(environment.AES_KEY, username),
      code: this.encrDecrService.encrypt(environment.AES_KEY, code),
      password: this.encrDecrService.encrypt(environment.AES_KEY, password),
    };
    return new Observable((obs) => {
      this.apiSrv.post('users/updatepassword', EndpointsCodes.UPDATE_USER_PWD, requestObj, { showError }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  updateUserPassword(email: string, oldPassword: string, newPassword: string, showError: boolean): Observable<BERespModel> {
    const requestObj = {
      email,
      oldPassword: this.encrDecrService.encrypt(environment.AES_KEY, oldPassword),
      newPassword: this.encrDecrService.encrypt(environment.AES_KEY, newPassword),
    };
    return new Observable((obs) => {
      this.apiSrv.post('users/changepassword', EndpointsCodes.CHANGE_USER_PWD, requestObj, { showError }).subscribe(
          (res) => obs.next(res),
          (err) => obs.error(err),
          () => obs.complete()
      );
    });
  }

  setNewUser(loginUser: LoginUser, isSocial: boolean): Observable<any> {
    const userReq = {
      firstName: this.encrDecrService.encrypt(environment.AES_KEY, loginUser.firstName),
      lastName: this.encrDecrService.encrypt(environment.AES_KEY, loginUser.lastName),
      cellphone: this.encrDecrService.encrypt(environment.AES_KEY, loginUser.phone),
      fieldSelectedToLogin: this.encrDecrService.encrypt(environment.AES_KEY, 'email'),
      client: {
        fiscalId: this.encrDecrService.encrypt(environment.AES_KEY, ParserUtils.set0ToFiscalId(loginUser.rutNumber, this.userLocal.geoCountryCode)),
        erpClientId: this.encrDecrService.encrypt(environment.AES_KEY, ParserUtils.set0ToErpClientId(String(loginUser.clientNumber), this.userLocal.geoCountryCode)),
      },
      email: '',
      password: '',
    };

    if (!isSocial) {
      userReq.email = this.encrDecrService.encrypt(environment.AES_KEY, loginUser.email.toLowerCase());
      userReq.password = this.encrDecrService.encrypt(environment.AES_KEY, loginUser.password);
    }

    return new Observable((obs) => {
      this.apiSrv.post('users', EndpointsCodes.POST_USER, userReq, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  getUserStatus(erpClientId: string, email: string): Observable<BERespModel> {
    const queryParams = this.apiSrv.generateQueryParams({ erpClientId, email });
    return new Observable((obs) => {
      this.apiSrv.get(`users/status${queryParams}`, EndpointsCodes.GET_USER_STATUS, {}).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  getIntegrationUserInfo(user: string): Observable<BERespModel>  {
    const countryId = this.user?.countryId || this.userLocal?.geoCountryCode || '';
    const endpoint = `login?user=${user}`;
    const finalUrl = `${this.apiSrv.BASE_URLS.serverUrlIntegrations}${countryId}/${endpoint}`
    return new Observable((obs) => {
      this.apiSrv.get('', EndpointsCodes.LOGIN_INTEGRATIONS, { customUrl: finalUrl, showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }  

  setDeviceInfo(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if(!params['platform'] || !params['device']) return;
      this.store.dispatch(UserActions.setDeviceInfo({    
          devicePlatform: params['platform'],
          device: params['device']  
        }
      ));
    });
  }

  signOut(): void {
    if (!this.user.uuid) {
      this.router.navigate(['/']);
    }
    this.store.dispatch(logout());
    this.store.dispatch(UserActions.loadJwt({ jwt: '' }));
    this.store.dispatch(UserActions.loadRefreshJwt({ refreshJwt: '' }));
    this.store.dispatch(UserActions.loadUuid({ uuid: '' }));
    this.store.dispatch(UserActions.loadCognitoUserName({ cognitoUserName: '' }));
  }

  getCurrencyByCountry(value: string | number): string {
    switch (this.user.countryId) {
      case CountryCodes.CHILE:
        return AppUtils.parseCurrencyWithoutDecimals(value, '$');
      case CountryCodes.ARGENTINA:
        return AppUtils.parseCurrencyRegular(value, '$');
      case CountryCodes.PARAGUAY:
        return AppUtils.parseCurrencyWithoutDecimals(value, '₲');
      case CountryCodes.BRASIL:
        return AppUtils.parseCurrencyRegular(value, 'R$');
      default:
        return AppUtils.parseCurrencyRegular(value, '$');
    }
  }

  cleanUserInfoStore() : void {
    this.store.dispatch(UserActions.cleanUserInformation());
  } 

  updateExternalSession(user: UserInfo): void {
    this.store.dispatch(
      UserActions.createUserSession({ externalData: user })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
