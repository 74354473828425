<div 
  class="radio" 
  [class.radio-disabled]="disabled" 
  (click)="_onTouchTargetClick($event)"
  >
  <input 
    #input 
    type="radio"
    [id]="inputId"
    [checked]="checked"
    [disabled]="disabled"
    [attr.name]="name"
    [attr.value]="value"
    [required]="required"
    (change)="_onInputInteraction($event)"
  >
  <div class="radio__container">
    <div 
      class="radio__outer-circle" 
      [class.checked]="checked"
      (click)="radioClick()"
    >
    </div>
    <div 
      class="radio__inner-circle"
      [class.checked]="checked">
    </div>
  </div>
</div>
<label [ngClass]="{'custom-width': customWidthLabel}" class="label-radio"  [for]="inputId">
  <ng-content></ng-content>
</label>