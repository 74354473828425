<div class="bg-transparent">
  <div
    *ngIf="timeRemaining && cart.hasDeliveryNotFrozenProducts"
    class="ka-card d-flex align-items-center bg-grey mb-3 justify-content-between align-items-center"
  >
    <div class="image red-circle">
      <svg>
        <use
          [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-clock'"
        />
      </svg>
    </div>
    <div class="text ml-16 mr-8">
      <strong *ngIf="cart.hasDeliveryFrozenProducts && isChileanUser">{{
        ROOT + 'NOT_FROZEN' | translate
      }}</strong>
      <span *ngIf="cart.hasDeliveryFrozenProducts && isChileanUser">{{
        ROOT + 'TIME_DELIVERY_FROZEN_LABEL' | translate
      }}</span>
      <strong *ngIf="!cart.hasDeliveryFrozenProducts || !isChileanUser">{{
        ROOT + 'TIME_DELIVERY_LABEL' | translate
      }}</strong>
      <strong>{{ cart.visitDate | kaDate: 'day mm/dd' }}</strong>
      <ng-container *ngIf="!(timeRemaining.hours | isNotANumber) || !(timeRemaining.minutes | isNotANumber) || !(timeRemaining.seconds | isNotANumber) " >
      {{ ROOT + 'TIME_PURCHASE_ON' | translate }}
      <strong
        >{{ timeRemaining.hours }}:{{ timeRemaining.minutes }}:{{
          timeRemaining.seconds
        }}
        hs</strong
      >
    </ng-container>
    </div>
    <p
      *ngIf="client.data.visitDates.length > 1"
      class="button d-flex flex-align-end align-items-center"
      id="mobile-show-order-s4"
      (click)="openSelectDeliveryDateModal()"
    >
      <span class="mod-text pointer">Modificar</span>
      <svg class="tabler-truck-card svg pointer" stroke-width="3.25">
        <use
          xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-right"
        />
      </svg>
    </p>
  </div>
  <div
    *ngIf="!timeRemaining && cart.hasDeliveryNotFrozenProducts"
    class="ka-card d-flex align-items-center bg-grey mb-3 justify-content-between align-items-center"
  >
    <div class="image red-circle">
      <svg>
        <use
          [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-clock'"
        />
      </svg>
    </div>
    <div class="text ml-16 mr-8">
      <strong *ngIf="cart.hasDeliveryFrozenProducts">{{
        ROOT + 'NOT_FROZEN' | translate
      }}</strong>
      <span *ngIf="cart.hasDeliveryFrozenProducts">{{
        ROOT + 'TIME_DELIVERY_FROZEN_LABEL' | translate
      }}</span>
      <strong *ngIf="!cart.hasDeliveryFrozenProducts">{{
        ROOT + 'TIME_DELIVERY_LABEL' | translate
      }}</strong>
      <strong>{{ cart.visitDate | kaDate: 'day mm/dd' }}</strong>
    </div>
    <p
      *ngIf="client.data.visitDates.length > 1"
      class="button d-flex flex-align-end align-items-center"
      id="mobile-show-order-s4"
      (click)="openSelectDeliveryDateModal()"
    >
      <span class="mod-text pointer">Modificar</span>
      <svg class="tabler-truck-card svg pointer" stroke-width="3.25">
        <use
          xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-right"
        />
      </svg>
    </p>
  </div>
  <div
    *ngIf="cart.hasDeliveryFrozenProducts && isChileanUser"
    class="ka-card d-flex align-items-center bg-grey justify-content-between align-items-center"
  >
    <div class="image red-circle">
      <svg>
        <use
          [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-clock'"
        />
      </svg>
    </div>
    <div class="text ml-16 mr-8">
      <strong>{{ ROOT + 'FROZEN' | translate }}</strong>
      <span>{{ ROOT + 'TIME_DELIVERY_FROZEN_LABEL' | translate }}</span>
      <strong>{{ cart.frozenVisitDate | kaDate: 'day mm/dd' }}</strong>
      <div class="edit-label" (click)="openSelectFrozenDeliveryDateModal()">
        <strong>
          {{ ROOT + 'EDIT' | translate }}
        </strong>
      </div>
    </div>
  </div>
</div>