import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-box-skeleton',
  template: ` <div [ngStyle]="getMyStyles()" class="skelt-load loader"></div> `,
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent {
  @Input() Cwidth;
  @Input() Cheight;
  @Input() circle: boolean;

  getMyStyles() {
    const myStyles = {
      'width.rem': this.Cwidth ? this.Cwidth : '',
      'height.rem': this.Cheight ? this.Cheight : '',
      'border-radius': this.circle ? '50%' : '',
    };
    return myStyles;
  }
}
