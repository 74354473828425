<p *ngIf="suggestedProducts.length > 0" class="title mt-0 mt-lg-3 mb-2">
  {{ 'NEW_ORDER.SUGGESTED_PRODUCTS.TITLE' | translate }}
</p>

<owl-carousel-o
  *ngIf="suggestedProducts?.length"
  [options]="suggestedCarouselOptions"
  class="w-100 mb-5 suggested-on-carousel"
>
  <ng-container *ngFor="let product of suggestedProducts; index as i">
    <ng-template carouselSlide>
      <app-ka-suggested-on-cart
        [product]="product"
        [stockDigital]="true"
      ></app-ka-suggested-on-cart>
    </ng-template>
  </ng-container>
</owl-carousel-o>
