import { KeyValuePipe } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { Discount } from 'src/app/core/models/discount.model';
import { Product } from 'src/app/core/models/product.model';
import { CartService } from 'src/app/core/services/cart.service';
import { ProductsService } from 'src/app/core/services/products.service';
import {
  deleteAnyProduct,
  upsertMultipleProducts,
} from 'src/app/core/state/actions/cart.actions';
import { getCartProductsDisc } from 'src/app/core/state/reducers/cart.reducer';
import { ValidationUtils } from 'src/app/core/utils/validation-utils';
import { environment } from 'src/environments/environment';
import { AppUtils } from '../../../../core/utils/app-utils';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-open-pack-modal',
  templateUrl: './open-pack-modal.component.html',
  styleUrls: ['./open-pack-modal.component.scss'],
})
export class OpenPackModalComponent implements OnInit, OnDestroy {
  readonly ROOT_LANG = 'NEW_ORDER.DISCOUNTS.OPEN.';
  readonly pillMsg = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  private subscriptions = new Subscription();
  @Input() public discount: Discount;
  productsGrouped: any;
  productsSelected: Product[] = [];
  requirementGroups: any[] = [];
  cartService: CartService;
  client: Client;
  cart: Cart;
  img_root = environment.WEB_DOMAIN;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;
  showTotal = false;
  calculatedTotal = 0;
  initialQuantities = {};
  maxOrderQuantity: number;

  @ViewChild('tooltip') tooltip: NgbTooltip;
  @ViewChild('maxQuantityTooltip') maxQuantityTooltip: NgbTooltip;
  @HostListener('document:click', ['$event.target', '$event'])
  onClick(targetElement, event) {
    if (targetElement.id === 'quantitySelected') {
      event.preventDefault();
      this.tooltip.close();
    }
  }

  constructor(
    private store: Store<{ cart: Cart; client: Client }>,
    public activeModal: NgbActiveModal,
    cartService: CartService,
    private productService: ProductsService,
    private keyValuePipe: KeyValuePipe,
  ) {
    this.cartService = cartService;
    this.subscriptions.add(
      this.store
        .select(getCartProductsDisc)
        .subscribe(() => this.activeModal.close()),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
  }

  ngOnInit(): void {
    this.setInitialQuantities();
    this.refreshProductsSelected();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addProduct(product): void {
    this.determineMaxOrderQuantity(product);
    if (product.quantitySelected + 1 >= this.maxOrderQuantity) {
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
    }
    if (product.quantitySelected + 1 > this.maxOrderQuantity) return;
    product.quantitySelected++;
    this.onQuantityChange(product.productId, product.quantitySelected);
    this.refreshProductsSelected();
  }

  removeProduct(product): void {
    if (product.quantitySelected > 0) {
      product.quantitySelected--;
      this.onQuantityChange(product.productId, product.quantitySelected);
      this.refreshProductsSelected();
    }
  }

  addProductsToCart(): void {
    if (!this.productsSelected.length) return;
    const cartQuantities = {};
    this.cart.products.forEach((cartProduct) => {
      if (this.client.subUnitAvailable) {
        if (cartProduct.subunitSelected !== 'BOT') {
          cartQuantities[cartProduct.productId] = {
            quantity: cartProduct.quantity,
            erpMeasureUnitId:
              cartProduct.subunitSelected || cartProduct.erpMeasureUnitId,
          };
        }
      } else {
        cartQuantities[cartProduct.productId] = {
          quantity: cartProduct.quantity,
          erpMeasureUnitId: cartProduct.erpMeasureUnitId,
        };
      }
    });
    this.productsSelected
      .filter((product) => product.quantitySelected === 0)
      .forEach((product) => {
        if (
          product.quantitySelected === 0 &&
          this.initialQuantities[product.productId] !== 0
        ) {
          this.store.dispatch(deleteAnyProduct({ product }));
        }
      });
    const productsWithCorrectQuantities = this.productsSelected
      .filter((product) => product.quantitySelected !== 0)
      .map((product) => {
        if (
          cartQuantities[product.productId]?.quantity &&
          cartQuantities[product.productId]?.erpMeasureUnitId !== 'BOT'
        ) {
          if (
            cartQuantities[product.productId].quantity <=
            product.quantitySelected
          ) {
            return {
              ...product,
              quantitySelected:
                product.quantitySelected -
                cartQuantities[product.productId].quantity,
              subunitSelected:
                product.subunitSelected || product.erpMeasureUnitId,
              erpMeasureUnitId:
                product.erpMeasureUnitId || product.erpUnitMeasureId,
            };
          } else {
            return {
              ...product,
              quantitySelected:
                product.quantitySelected -
                cartQuantities[product.productId].quantity,
              subunitSelected:
                product.subunitSelected ||
                product.erpMeasureUnitId ||
                product.erpUnitMeasureId,
              erpMeasureUnitId:
                product.erpMeasureUnitId || product.erpUnitMeasureId,
            };
          }
        }
        return {
          ...product,
          subunitSelected: product.subunitSelected || product.erpMeasureUnitId,
          erpMeasureUnitId:
            product.erpMeasureUnitId || product.erpUnitMeasureId,
        };
      });
    this.store.dispatch(
      upsertMultipleProducts({ products: productsWithCorrectQuantities }),
    );
    this.cartService.updateDeliveryProducts();
  }

  refreshProductsSelected(): void {
    this.showTotal = false;

    this.productsGrouped = this.productsGrouped.map((productGroup) => {
      let groupQuantityCount = 0;
      const mockGroupProducts = this.keyValuePipe.transform(
        productGroup.products,
      );
      mockGroupProducts.forEach((mockGroupProductsItem: any) => {
        mockGroupProductsItem.value.forEach((product) => {
          //Si el producto esta agregado en producto seleccionado
          const isAlreadySelected = this.productsSelected.some(
            (p) => p.productId === product.productId,
          );

          //Cantidad total del grupo
          groupQuantityCount = groupQuantityCount + product.quantitySelected;

          //Si el producto esta seleccionado, y la cantidad es mayor a cero, entonces deberia setearle la nueva cantidad
          if (isAlreadySelected) {
            if (product.quantitySelected > 0) {
              this.productsSelected.find(
                (p) => p.productId === product.productId,
              ).quantity = product.quantity;
            }
          }

          if (!isAlreadySelected && product.quantitySelected > 0)
            this.productsSelected.push(product);
          productGroup.groupQuantityCount = groupQuantityCount;
          productGroup.reachedLimit =
            groupQuantityCount >= productGroup.quantity;
        });
      });
      return productGroup;
    });
  }

  calculatePrice() {
    this.productService
      .getDiscountCalculation(this.discount.discountId, this.productsSelected)
      .subscribe((res) => {
        res.data.calculatedItems.forEach((product) => {
          const productToUpdate = this.requirementGroups
            .map((req) => req.products)
            .flat()
            .find((prod) => prod.productId === product.productId);
          productToUpdate.price = product.price;
          // tal vez haya que sacar esto y calcularlo en el backend directamente como se hace en getDiscounts
          productToUpdate.price.finalPriceWithoutDiscount =
            parseFloat(productToUpdate.price.listPrice) +
            parseFloat(productToUpdate.price.taxes) +
            parseFloat(productToUpdate.price.shippingPrice);
          productToUpdate.quantitySelected = product.quantity;
        });
        this.calculatedTotal = this.requirementGroups.reduce(
          (acc1, req) =>
            acc1 +
            req.products.reduce(
              (acc2, prod) =>
                acc2 + prod.price.finalPrice * prod.quantitySelected,
              0,
            ),
          0,
        );
        this.refreshProductsSelected();
        this.showTotal = true;
      });
  }

  setInitialQuantities(): void {
    if (!this.discount) return;
    // clone products to avoid mutating the original prices and set initial quantity and price to show without discount
    this.requirementGroups = this.discount.requirements.map((req) => {
      const products = this.mapInitialProducts(req);
      return {
        ...req,
        products,
        reachedLimit: false,
        groupQuantityCount: this.getGroupInitialQuantites(products),
      };
    });
    this.productsGrouped = this.requirementGroups.map((req) => ({
      ...req,
      products: AppUtils.groupBy(req.products, 'productGroupName'),
    }));
  }

  mapInitialProducts(requirement) {
    // requerimiento abierto o cerrado
    const products = requirement.products || [requirement];
    return products.map((product) => {
      const quantitySelected = this.getInitialQuantitiesOnCart(product);
      this.initialQuantities[product.productId] = quantitySelected;
      return {
        ...product,
        quantitySelected,
        subunitSelected: product.subunitSelected || product.erpMeasureUnitId,
        enabledToSellBySubUnit: this.client.subUnitAvailable,
      };
    });
  }

  validQuantLength(event, product?): void {
    const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (product.quantitySelected === 0) {
      product.quantitySelected = 1;
      event.preventDefault();
      return;
    }

    if (validKeys.some((validKey) => validKey === event.key)) return;

    this.determineMaxOrderQuantity(product);

    if (
      event.target.value.length >
      this.maxOrderQuantity.toString().length - 1
    ) {
      event.preventDefault();
      return;
    }

    if (
      product.quantitySelected > this.maxOrderQuantity ||
      !/^\d$/.test(event.key)
    )
      event.preventDefault();
  }

  validMaxQuantity(event, product): void {
    this.determineMaxOrderQuantity(product);
    if (+event.target.value > this.maxOrderQuantity) {
      product.quantitySelected = this.maxOrderQuantity;
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
      event.preventDefault();
    }
  }

  validQuantCalc(event, product: Product): void {
    if (event.target.value.length < 1) {
      product.quantitySelected = 0;
      event.preventDefault();
    }
  }

  onQuantityPaste(event, product: Product): void {
    this.determineMaxOrderQuantity(product);
    if (event?.clipboardData?.getData('Text') > this.maxOrderQuantity) {
      product.quantitySelected = this.maxOrderQuantity;
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
      event.preventDefault();
    }
    return ValidationUtils.validRegexOnPaste(/^\d+$/, event);
  }

  showTooltipForThreeSeconds(tooltip) {
    if (!tooltip.isOpen()) {
      of(tooltip.open())
        .pipe(delay(3000))
        .subscribe({ next: () => tooltip.close() });
    }
  }

  onImgError(event): void {
    event.target.src = this.notFoundImg;
  }

  private getInitialQuantitiesOnCart(product): number {
    let productInCart = this.cart.products.find((cartProduct) => {
      return (
        this.client?.subUnitAvailable &&
        cartProduct?.productId === product?.productId &&
        cartProduct?.subunitSelected !== 'BOT'
      );
    });
    if (!productInCart) {
      productInCart = this.cart.products.find((cartProduct) => {
        return (
          !this.client?.subUnitAvailable &&
          cartProduct?.productId === product?.productId
        );
      });
    }
    const quantity = productInCart ? productInCart.quantity : 0;
    return quantity;
  }

  private getGroupInitialQuantites(products): number {
    const result = products.reduce(
      (acc, product) => acc + product.quantitySelected,
      0,
    );
    return result;
  }

  determineMaxOrderQuantity(product: Product) {
    const isBottleSelected = product.erpMeasureUnitId === 'BOT';
    this.maxOrderQuantity = isBottleSelected
      ? product.maxOrderQuantityBottle
      : product.maxOrderQuantityBox;
  }

  onQuantityChange(productId: number, newQuantity: number): void {
    this.productsGrouped.forEach((group) => {
      Object.values(group.products).forEach((productArray: any) => {
        productArray.forEach((product) => {
          if (product.productId === productId) {
            product.quantitySelected = newQuantity;
          }
        });
      });
    });
  }
}
