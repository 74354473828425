import { createAction, props } from '@ngrx/store';
import { Product } from '../../models/product.model';
import { BERespErrorModel } from '../../models/backend/BE-response-error.model';
import { PaymentMethod } from '../../models/payment-method.model';
import { ProductFilter } from '../../models/product-filter.model';
import { VisitDate } from '../../models/client.model';
import { PaymentMethodBySegement } from '../../models/payment-method-by-segment.model';

export const upsertProduct = createAction(
  '[Cart] upsertProduct',
  props<{ product: Product }>(),
);
export const upsertMultipleProducts = createAction(
  '[Cart] upsertMultipleProducts',
  props<{ products: Product[] }>(),
);
export const deleteProduct = createAction(
  '[Cart] deleteProduct',
  props<{ product: Product }>(),
);
export const deleteAnyProduct = createAction(
  '[Cart] deleteAnyProduct',
  props<{ product: Product }>(),
);
export const deleteAllProducts = createAction('[Cart] deleteAllProducts');
export const bulkDeleteProducts = createAction(
  '[Cart] bulkDeleteProducts',
  props<{ products: Product[] }>(),
);
export const bulkDeleteAnyProducts = createAction(
  '[Cart] bulkDeleteAnyProducts',
  props<{ products: Product[] }>(),
);
export const deleteAllProductsFrozen = createAction(
  '[Cart] deleteAllProductsFrozen',
);
export const deleteAllProductsNotFrozen = createAction(
  '[Cart] deleteAllProductsNotFrozen',
);
export const updateAllProducts = createAction(
  '[Cart] updateAllProducts',
  props<{ products: Product[] }>(),
);
export const loadProductDiscountsSuccess = createAction(
  '[Cart] loadProductDiscountsSuccess',
  props<{ data: any }>(),
);
export const loadProductDiscountsError = createAction(
  '[Cart] loadProductDiscountsError',
  props<{ error: BERespErrorModel }>(),
);
export const updateMinPurchase = createAction(
  '[Cart] updateMinPurchase',
  props<{ minPurchase: number }>(),
);
export const updateMinBoxesUser = createAction(
  '[Cart] updateMinBoxesUser',
  props<{ minBoxesUser: number }>(),
);
export const updateOffRoute = createAction(
  '[Cart] updateOffRoute',
  props<{ offRoute: boolean }>(),
);
export const updateMinPurchaseFrozen = createAction(
  '[Cart] updateMinPurchaseFrozen',
  props<{ minPurchaseFrozen: number }>(),
);
export const updateMinPurchaseReached = createAction(
  '[Cart] updateMinPurchaseReached',
  props<{ minPurchaseReached: boolean }>(),
);
export const updateMinPurchaseFrozenReached = createAction(
  '[Cart] updateMinPurchaseFrozenReached',
  props<{ minPurchaseFrozenReached: boolean }>(),
);

export const updateVisitDate = createAction(
  '[Cart] updateVisitDate',
  props<{ date: string }>(),
);
export const updateVisitDateSuccess = createAction(
  '[Cart] updateVisitDateSuccess',
  props<{ date: string }>(),
);
export const updateVisitDateError = createAction('[Cart] updateVisitDate');

export const updateDeliveryDate = createAction(
  '[Cart] updateDeliveryDate',
  props<{ date: VisitDate }>(),
);
export const updateDeliveryDateSuccess = createAction(
  '[Cart] updateDeliveryDateSuccess',
  props<{ date: string }>(),
);
export const updateDeliveryDateError = createAction(
  '[Cart] updateDeliveryDateError',
);

export const updateConfirmDeleteProduct = createAction(
  '[Cart] updateConfirmDeleteProduct',
  props<{ product: Product }>(),
);

export const loadDeliveryDates = createAction('[Cart] loadDeliveryDates');
export const loadDeliveryDatesSuccess = createAction(
  '[Cart] loadDeliveryDatesSuccess',
);
export const loadDeliveryDatesError = createAction(
  '[Cart] loadDeliveryDatesError',
);

export const loadFrozenDeliveryDates = createAction(
  '[Cart] loadFrozenDeliveryDates',
);
export const loadFrozenDeliveryDatesSuccess = createAction(
  '[Cart] loadFrozenDeliveryDatesSuccess',
);
export const loadFrozenDeliveryDatesError = createAction(
  '[Cart] loadFrozenDeliveryDatesError',
);

export const deliveryFrozenActions = createAction(
  '[Cart] deliveryFrozenActions',
  props<{ product: Product }>(),
);
export const deliveryFrozenActionsSuccess = createAction(
  '[Cart] deliveryFrozenActionsSuccess',
);
export const deliveryFrozenActionsError = createAction(
  '[Cart] deliveryFrozenActionsError',
);

export const setFrozenOperationDate = createAction(
  '[Cart] setFrozenOperationDate',
);
export const setFrozenOperationDateSuccess = createAction(
  '[Cart] setFrozenOperationDateSuccess',
);
export const setFrozenOperationDateError = createAction(
  '[Cart] setFrozenOperationDateError',
);

export const setFrozenOperationDateAndAddProduct = createAction(
  '[Cart] setFrozenOperationDateAndAddProduct',
  props<{ product: Product }>(),
);
export const setFrozenOperationDateAndAddProductSuccess = createAction(
  '[Cart] setFrozenOperationDateAndAddProductSuccess',
);
export const setFrozenOperationDateAndAddProductError = createAction(
  '[Cart] setFrozenOperationDateAndAddProductError',
);

export const updateRepeated = createAction(
  '[Cart] updateRepeated',
  props<{ isRepeated: boolean }>(),
);
export const updateFrozenVisitDate = createAction(
  '[Cart] updateFrozenVisitDate',
  props<{ date: string | Date }>(),
);
export const updateInvoiceDeadline = createAction(
  '[Cart] updateInvoiceDeadline',
  props<{ invoiceDeadline: string }>(),
);
// export const setIsFirstDeliveryDate = createAction('[Cart] setIsFirstDeliveryDate', props<{ isFirstDeliveryDate: boolean }>());

export const cleanCart = createAction('[Cart] cleanCart');
export const updateHasDeliveryFrozenProducts = createAction(
  '[Cart] updateHasDeliveryFrozenProducts',
);
export const updateHasDeliveryNotFrozenProducts = createAction(
  '[Cart] updateHasDeliveryNotFrozenProducts',
);

export const upsertpaymentMethod = createAction(
  '[Cart] upsertpaymentMethod',
  props<{ paymentMethod: PaymentMethod }>(),
);

export const updateCartCredits = createAction(
  '[Cart] updateCartCredits',
  props<{ credits: any }>(),
);
export const removeOrderId = createAction('[Cart] removeOrderId');
export const upsertPurchaseOrderNumber = createAction(
  '[Cart] upsertPurchaseOrderNumber',
  props<{ purchaseOrderNumber: string }>(),
);
export const hasTermsConditionsAccepted = createAction(
  '[Cart] hasTermsConditionsAccepted',
  props<{ hasTermsConditionsAccepted: boolean }>(),
);
export const updateFilters = createAction(
  '[Cart] updateFilters',
  props<{ productFilters: ProductFilter }>(),
);
export const hasOrderError = createAction(
  '[Cart] hasOrderError',
  props<{ hasOrderError: boolean }>(),
);

export const confirmOrder = createAction('[Cart] confirmOrder');
export const confirmOrderSuccess = createAction('[Cart] confirmOrderSuccess');
export const confirmOrderError = createAction('[Cart] confirmOrderError');

export const hasPerceptionAlertOpen = createAction(
  '[Cart] hasPerceptionAlertOpen',
  props<{ hasPerceptionAlertOpen: boolean }>(),
);

export const refreshOrderOnUpdateDate = createAction(
  '[Cart] refreshOrderOnUpdateDate',
);
export const haslockedCategoryAlertOpen = createAction(
  '[Cart] haslockedCategoryAlertOpen',
  props<{ haslockedCategoryAlertOpen: boolean }>(),
);

export const addFavoriteProduct = createAction(
  '[Cart] addFavoriteProduct',
  props<{ product: Product }>(),
);
export const addFavoriteProductSuccess = createAction(
  '[Cart] addFavoriteProductSuccess',
);
export const addFavoriteProductError = createAction(
  '[Cart] addFavoriteProductError',
);

export const removeFavoriteProduct = createAction(
  '[Cart] removeFavoriteProduct',
  props<{ product: Product }>(),
);
export const removeFavoriteProductSuccess = createAction(
  '[Cart] removeFavoriteProductSuccess',
);
export const removeFavoriteProductError = createAction(
  '[Cart] removeFavoriteProductError',
);

export const cleanCartWithoutFilters = createAction(
  '[Cart] cleanCartWithoutFilters',
);

export const updateMultiplePaymentMethod = createAction(
  '[Cart] updateMultiplePaymentMethod',
  props<{ multiplePaymentMethodsBySegment: PaymentMethodBySegement[] }>(),
);
