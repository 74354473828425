import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from '../../../core/models/client.model';
import { ProductScaleModalComponent } from '../../../pages/new-order/modals/product-scale-modal/product-scale-modal.component';
import { ProductsService } from '../../../core/services/products.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductTypes } from 'src/app/core/enums/product-types';
import { CartService } from 'src/app/core/services/cart.service';
import { ScalePackModalComponent } from 'src/app/pages/new-order/modals/scale-pack-modal/scale-pack-modal.component';
import { CountryCodes } from '../../../core/enums/country-codes.enum';

import { env } from 'src/app/app.component';

@Component({
  selector: 'app-ka-simple-product',
  templateUrl: './ka-simple-product.component.html',
  styleUrls: ['./ka-simple-product.component.scss'],
})
export class KaSimpleProductComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() product?: Product;
  @Input() page?: string;
  readonly ProductTypes = ProductTypes;
  homeStyle: number;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;
  unavailable = false;
  cart: Cart;
  readonly ROOT_LANG = 'NEW_ORDER.ORDER_DETAIL.';
  readonly pillMsg = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  readonly ROOT = 'NEW_ORDER.SUGGESTED_PRODUCTS.';
  subUnits = [];
  client: Client;
  subUnitSelected: string;
  minSubunit: number;
  productSuggetedLabel: boolean;
  enableProductPriceDisclaimer = false;

  constructor(
    private store: Store<{ homeStyle: number; cart: Cart; client: Client }>,
    private productsService: ProductsService,
    private ngbModalService: NgbModal,
    public cartService: CartService,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.homeStyle = env.getConfigByCountry().homeStyle;
  }

  ngOnInit(): void {
    this.unavailable = !this.product.availability || this.product.locked;
    if (this.product.enabledToSellBySubUnit)
      this.subUnitSelected = this.product.subunitSelected
        ? this.product.subunitSelected
        : this.product.erpUnitMeasureId;
    this.minSubunit = this.product.subUnit.multiple
      ? this.product.subUnit.multiple
      : 1;
    this.productSuggetedLabel =
      this.page === ProductTypes.UNIT &&
      this.product.suggestedProduct.isSuggested;
    this.enableProductPriceDisclaimer =
      this.product.price.listPrice > this.product.price.finalPrice &&
      this.client.state === CountryCodes.CHILE;
  }

  onImgError(): void {
    this.product.image = this.notFoundImg;
  }

  handleProductScale(product): void {
    this.productsService
      .getDiscount(true, product.relatedDiscount)
      .subscribe((res) => {
        const discount: any = res;

        const modalType =
          discount?.config?.class === 'close'
            ? ProductScaleModalComponent
            : ScalePackModalComponent;

        const scaleModal = this.ngbModalService.open(modalType, {
          windowClass: 'ngbmodal-centered',
          size: 'lg',
        });

        if (discount?.config?.class === 'close') {
          scaleModal.componentInstance.product = discount;
        }

        if (discount?.config?.class === 'open') {
          scaleModal.componentInstance.discount = discount;
        }
      });
  }

  updateSubUnitQuantity(event): void {
    if (event) {
      this.subUnitSelected = event;
      const productSubunitQuantity = this.cart.products.find(
        (prod) =>
          prod.productId === this.product.productId &&
          prod.subunitSelected === event,
      );
      const suggestedQuantity = this.product.suggestedProduct.isSuggested
        ? this.product.suggestedProduct.quantity
        : 1;
      const quantity =
        this.product.subunitSelected === 'BOT' && this.minSubunit
          ? this.minSubunit
          : suggestedQuantity;
      this.product.quantitySelected = productSubunitQuantity
        ? productSubunitQuantity.quantity
        : quantity;
      this.product.subunitSelected = event;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
