import { createAction, props } from '@ngrx/store';
import { Client, SuggestedProducts, VisitDate } from '../../models/client.model';
import { Order } from '../../models/order.model';
import { Product } from '../../models/product.model';
import { ClientDiscretionaryDiscount } from '../../models/benefit.model';
import { MyCreditModel } from '../../models/my-account.model';
import { Draft } from '../../models/draft.model';
import { PaymentMethod } from '../../models/payment-method.model';

export const loadClient = createAction('[Client] loadClient');
export const loadClientSuccess = createAction('[Client] loadClientSuccess', props<{ client: Client }>());
export const loadClientError = createAction('[Client] loadClientError');

export const updateClient = createAction('[Client] updateClient', props<{ client: Client }>());
export const updateClientSuccess = createAction('[Client] updateClientSuccess', props<{ client: Client }>());
export const updateClientError = createAction('[Client] updateClientError');

export const loadVisitDates = createAction('[Client] loadVisitDates');
export const loadVisitDatesSuccess = createAction('[Client] loadVisitDatesSuccess', props<{ visitDates: VisitDate[], visitDate: string }>());
export const loadVisitDatesError = createAction('[Client] loadVisitDatesError');

export const loadFrozenVisitDates = createAction('[Client] loadFrozenVisitDates');
export const loadFrozenVisitDatesSuccess = createAction('[Client] loadFrozenVisitDatesSuccess', props<{ frozenVisitDates: VisitDate[] }>());
export const loadFrozenVisitDatesError = createAction('[Client] loadFrozenVisitDatesError');

export const loadOrders = createAction('[Client] loadOrders');
export const loadOrdersSuccess = createAction('[Client] loadOrdersSuccess', props<{ orders: Order[] }>());
export const loadOrdersError = createAction('[Client] loadOrdersError');

export const loadLastOrder = createAction('[Client] loadLastOrder');
export const loadLastOrderSuccess = createAction('[Client] loadLastOrderSuccess', props<{ lastOrder: Order }>());
export const loadLastOrderError = createAction('[Client] loadLastOrderError');

export const loadSuggestedProducts = createAction('[Client] loadSuggestedProducts');
export const loadSuggestedProductsSuccess = createAction('[Client] loadSuggestedProductsSuccess', props<{ suggestedProducts: SuggestedProducts }>());
export const loadSuggestedProductsError = createAction('[Client] loadSuggestedProductsError');

export const loadDiscountProducts = createAction('[Client] loadDiscountProducts');
export const loadDiscountProductsSuccess = createAction('[Client] loadDiscountProductsSuccess', props<{ discountProducts: Product[] }>());
export const loadDiscountProductsError = createAction('[Client] loadDiscountProductsError');

export const loadDiscretionaryDiscount = createAction('[Client] loadDiscretionaryDiscount');
export const loadDiscretionaryDiscountSuccess = createAction('[Client] loadDiscretionaryDiscountSuccess', props<{ discounts: ClientDiscretionaryDiscount[] }>());
export const loadDiscretionaryDiscountError = createAction('[Client] loadDiscretionaryDiscountError');

export const loadDrafts = createAction('[Client] loadDrafts');
export const loadDraftsSuccess = createAction('[Client] loadDraftsSuccess', props<{ drafts: Draft[] }>());
export const loadDraftsError = createAction('[Client] loadDraftsError');
export const deleteDraft = createAction('[Client] deleteDraft', props<{ draft: Draft }>());
export const deleteDraftSuccess = createAction('[Client] deleteDraftSuccess', props<{ draft: Draft }>());
export const deleteDraftError = createAction('[Client] deleteDraftError');

export const loadPaymentMethod = createAction('[Client] loadPaymentMethod');
export const loadPaymentMethodSuccess = createAction('[Client] loadPaymentMethodSuccess', props<{ paymentMethods: PaymentMethod[] }>());
export const loadPaymentMethodError = createAction('[Client] loadPaymentMethodError');

export const loadCredits = createAction('[Client] loadCredits');
export const loadCreditsSuccess = createAction('[Client] loadCreditsSuccess', props<{ credits: MyCreditModel[] }>());
export const loadCreditsError = createAction('[Client] loadCreditsError');

export const loadInitClientSession = createAction('[Client] loadInitClientSession');
export const loadInitClientSessionSuccess = createAction('[Client] loadInitClientSessionSuccess');
export const loadInitClientSessionError = createAction('[Client] loadInitClientSessionError');

export const updateOperationDatesSuccess = createAction('[Client] updateOperationDatesSuccess');
export const updateOperationDatesError = createAction('[Client] updateOperationDatesError');

export const setClientHasLockOrder = createAction('[Client] setClientHasLockOrder', props<{ hasLockOrder: boolean }>());

export const lockOrderMissingPayment = createAction('[Client] lockOrderMissingPayment', props<{ lockOrderMissingPayment: boolean }>());

export const setLockType = createAction('[Client] setLockType', props<{ lockType: string }>());

export const checkClientLocks = createAction('[Client] checkClientLocks');
export const checkClientLocksSuccess = createAction('[Client] checkClientLocksSuccess');
export const checkClientLocksError = createAction('[Client] checkClientLocksError');

export const updateHasCreditLock = createAction('[Client] updateHasCreditLock', props<{ hasCreditLock: boolean }>());

export const updateSuggestedFavoriteProduct = createAction('[Client] updateSuggesteFavoriteProduct', props<{product:Product}>());

export const loadDiscountProductsForOffert = createAction('[Client] loadDiscountProductsForOffert', props<{ isDiscount:boolean }>());
export const loadDiscountProductsForOffertSuccess = createAction('[Client] loadDiscountProductsForOffertSuccess',props<{ discountProductsforOffert:Product[] }>());
export const loadDiscountProductsForOffertError = createAction('[Client] loadDiscountProductsForOffertError');

export const updateMinPurchase = createAction('[Client] updateMinPurchase', props<{ amountMinRoute: number }>());
export const updateMinPurchaseFrozen = createAction('[Client] updateMinPurchaseFrozen', props<{ amountMinFrozen: number }>());

export const updateExternalClientInfo = createAction('[Client] updateExternalClientInfo', props<{ client: Client }>());