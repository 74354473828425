import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product } from 'src/app/core/models/product.model';
import { environment } from 'src/environments/environment';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { Client } from "../../../core/models/client.model";
import { Cart } from "../../../core/models/cart.model";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-ka-suggested-on-cart',
  templateUrl: './ka-suggested-on-cart.component.html',
  styleUrls: ['./ka-suggested-on-cart.component.scss'],
})
export class KaSuggestedOnCartComponent implements OnDestroy {
  @Input() product: Product;
  @Input() stockDigital = false;
  private subscriptions = new Subscription();
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;
  user: UserInfo;
  client: Client;
  cart: Cart;

  constructor(
      private store: Store<{ homeStyle: number; user: UserInfo; client: Client; cart: Cart }>,
      ngbMenu: NgbDropdownConfig) {
      
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.client = client)));
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.user = user)));
    ngbMenu.placement = this.isMobile() ? 'left' : 'top-start';
  }


  onImgError(): void {
    this.product.image = this.notFoundImg;
  }

  isMobile(): boolean {
    return screen.width < 992;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
