import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment';
import { DefaultImages } from 'src/app/core/enums/default-images';


@Component({
  selector: 'app-product-image-modal',
  templateUrl: './product-image-modal.component.html',
  styleUrls: ['./product-image-modal.component.scss']
})
export class ProductImageModalComponent {
  @Input() imageUrl: string;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  confirm(): void {
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.close(false);
  }

  onImgError(){
    this.imageUrl = this.notFoundImg;
  }
}