import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Cart } from 'src/app/core/models/cart.model';
import { Product } from 'src/app/core/models/product.model';
import { environment } from 'src/environments/environment';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { ProductScaleModalComponent } from '../../../pages/new-order/modals/product-scale-modal/product-scale-modal.component';
import { ProductsService } from '../../../core/services/products.service';
import { ProductPrice } from '../../../core/models/product-price.model';
import { Client } from '../../../core/models/client.model';
import { CountryCodes } from '../../../core/enums/country-codes.enum';
import { UserLocal } from '../../../core/models/user-local.model';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { ProductTypes } from 'src/app/core/enums/product-types';
import { CartService } from 'src/app/core/services/cart.service';
import { PageNames } from 'src/app/core/enums/page-names';
import { ScalePackModalComponent } from 'src/app/pages/new-order/modals/scale-pack-modal/scale-pack-modal.component';

import { env } from 'src/app/app.component';

@Component({
  selector: 'app-ka-product-not-available',
  templateUrl: './ka-product-not-available.component.html',
  styleUrls: ['./ka-product-not-available.component.scss'],
})
export class KaProductNotAvailableComponent implements OnInit, OnDestroy {
  @Input() product?: Product;
  @Input() page?: string;
  @Input() carousel = false;
  @Input() style = 'GRID'
  private subscriptions = new Subscription();
  readonly ProductTypes = ProductTypes;
  readonly PageNames = PageNames;
  readonly pillMsg = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  readonly ROOT_LANG = 'NEW_ORDER.ORDER_DETAIL.';
  readonly ROOT = 'NEW_ORDER.SUGGESTED_PRODUCTS.';
  homeStyle: any;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;
  unavailable = false;
  cart: Cart;
  client: Client;
  countryCode: CountryCodes;
  user: UserInfo;
  image: string;
  subUnitSelected: string;
  currentScaleDiscount: ProductPrice;
  minSubunit: number;
  productSuggetedLabel : boolean;

  constructor(
    private ngbModalService: NgbModal,
    private productsService: ProductsService,
    private store: Store<{
      user: UserInfo;
      client: Client;
      userLocal: UserLocal;
      cart: Cart;
    }>,
    public cartService: CartService,
    ngbMenu: NgbDropdownConfig,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.homeStyle = env.getConfigByCountry().homeStyle;
    ngbMenu.placement = 'bottom-start';
  }

  ngOnInit(): void {
    this.unavailable = !this.product.availability || this.product.locked;
    if (this.product.image.includes(DefaultImages.PRODUCT)) {
      const imgName =
        this.user.cpgId +
        '_' +
        this.user.countryId +
        '_' +
        this.user.organizationId +
        '_';
      this.product.image =
        environment.WEB_DOMAIN +
        'images/products/' +
        imgName +
        this.product.erpProductId +
        '.png';
    }
    if (this.product.enabledToSellBySubUnit)
      this.subUnitSelected = this.product.subunitSelected
        ? this.product.subunitSelected
        : this.product.erpUnitMeasureId;

    this.minSubunit = this.product.subUnit.multiple
      ? this.product.subUnit.multiple
      : 1;
      this.productSuggetedLabel  =  this.product.suggestedProduct.isSuggested;
      this.carousel = this.page === PageNames.HOME;
  }

  onImgError(): void {
    this.product.image = this.notFoundImg;
  }

  handleProductScale(product): void {
    if(this.carousel) return;
    this.productsService
      .getDiscount(true, product.relatedDiscount)
      .subscribe((res) => {
        const discount: any = res;

        const modalType =
          discount?.config?.class === 'close'
            ? ProductScaleModalComponent
            : ScalePackModalComponent;

        const scaleModal = this.ngbModalService.open(modalType, {
          windowClass: 'ngbmodal-centered',
          size: 'lg',
        });

        if (discount?.config?.class === 'close') {
          scaleModal.componentInstance.product = discount;
        }

        if (discount?.config?.class === 'open') {
          scaleModal.componentInstance.discount = discount;
        }
      });
  }

  updateSubUnitQuantity(event): void {
    if (event) {
      this.subUnitSelected = event;
      const productSubunitQuantity = this.cart.products.find(
        (prod) =>
          prod.productId === this.product.productId &&
          prod.subunitSelected === event,
      );
      const suggestedQuantity = this.product.suggestedProduct.isSuggested
        ? this.product.suggestedProduct.quantity
        : 1;
      const quantity =
        this.product.subunitSelected === 'BOT' && this.minSubunit
          ? this.minSubunit
          : suggestedQuantity;
      this.product.quantitySelected = productSubunitQuantity
        ? productSubunitQuantity.quantity
        : quantity;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
