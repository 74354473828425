import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { BERespModel } from 'src/app/core/models/backend/BE-response.model';
import { Cart } from 'src/app/core/models/cart.model';
import { MissingBottlesInfo } from 'src/app/core/models/missing-bottles-info.model';
import { Product } from 'src/app/core/models/product.model';
import { CartService } from 'src/app/core/services/cart.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { upsertMultipleProducts } from 'src/app/core/state/actions/cart.actions';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-missing-bottles-modal',
  templateUrl: './missing-bottles-modal.component.html',
  styleUrls: ['./missing-bottles-modal.component.scss'],
})
export class MissingBottlesModalComponent implements OnInit {
  ROOT_LANG = 'NEW_ORDER.MISSING_BOTTLES.';
  img_root = environment.WEB_DOMAIN;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.PRODUCT;
  productsSelected: Product[] = [];
  products: Product[] = [];
  productGroups: MissingBottlesInfo[];

  @ViewChild('maxQuantityTooltip') maxQuantityTooltip: NgbTooltip;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<{ cart: Cart }>,
    public cartService: CartService,
    private productsService: ProductsService
  ) { }

  ngOnInit(): void {
    this.loadMissingBottlesInformation();
  }

  loadMissingBottlesInformation() {

    this.productsService
      .getMissingBottlesInformation()
      .subscribe((res: BERespModel) => {
        this.productGroups = res.data;
        this.productGroups.forEach((group) => {
          group.products = group.products.map((product) => ({
            ...product,
            name: product.name.split(product.size)[0],
            quantity: 0,
            missingBottles: group.missingQuantity,
            groupQuantityCount: 0,
          }));
          group.reachedLimit = false;
          group.boxSize = group.products[0].subunitquantity;
          group.groupQuantityCount = 0;
        });
      });
  }

  addProduct(product, productGroup): void {
    if (product.quantity + 1 > product.maxOrderQuantity) {
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
    }
    if (product.quantity + 1 > product.maxOrderQuantity) return;
    if (!productGroup.reachedLimit) {
      product.quantity = product.quantity + product.subUnit.multiple;
      this.refreshProductsSelected();
    }
  }

  removeProduct(product): void {
    if (product.quantity > 0) {
      product.quantity = product.quantity - product.subUnit.multiple;
      this.refreshProductsSelected();
    }
  }

  addProductsToCart(): void {
    if (!this.productsSelected.length) return;
    this.productsSelected = this.productsSelected.map((product) => ({
      ...product,
      subunitSelected: 'BOT',
      quantitySelected: product.quantity,
    }));
    this.store.dispatch(
      upsertMultipleProducts({ products: this.productsSelected })
    );
    this.cartService.updateDeliveryProducts();
    this.activeModal.close();
  }

  refreshProductsSelected(): void {
    this.productGroups = this.productGroups.map(productGroup => {
      let groupQuantityCount = 0;
      productGroup.products = productGroup.products.map(product => {
        const isAlreadySelected = this.productsSelected.some(
          (p) => p.productId === product.productId
        );
        groupQuantityCount = groupQuantityCount + product.quantity;
    
        if (isAlreadySelected) {
          if (product.quantity > 0)
            this.productsSelected.find(
              (p) => p.productId === product.productId
            ).quantity = product.quantity;
          if (product.quantity === 0)
            this.productsSelected = this.productsSelected.filter(
              (p) => p.productId !== product.productId
            );
        }
    
        if (!isAlreadySelected && product.quantity > 0)
          this.productsSelected.push(product);
    
        product.groupQuantityCount = groupQuantityCount;
        productGroup.groupQuantityCount = groupQuantityCount;
        productGroup.reachedLimit =
          groupQuantityCount >= productGroup.missingQuantity;
    
        return {...product};
      });
    
      return {...productGroup};
    });
  }

  validQuantLength(event, product: Product): void {
    const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (validKeys.some((validKey) => validKey === event.key)) return;
    if (!/^\d$/.test(event.key)) event.preventDefault();

    if (
      product.groupQuantityCount + parseFloat(event.key) >
      product.missingBottles
    ) {
      event.preventDefault();
      product.quantity = product.missingBottles - product.groupQuantityCount;
    }
  }

  validQuantCalc(event, product: Product): void {
    const value = product.quantity / product.subUnit.multiple;
    const isMultiplo = this.isInteger(value);
    if (!isMultiplo)
      product.quantity = Math.ceil(value) * product.subUnit.multiple;

    if (
      product.groupQuantityCount + product.quantity >
      product.missingBottles
    ) {
      event.preventDefault();
      product.quantity = product.missingBottles - product.groupQuantityCount;
    }

    if (product.quantity >= product.maxOrderQuantity) {
      product.quantity = product.maxOrderQuantity;
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
      event.preventDefault();
    }
  }

  onQuantityPaste(event, product): void {
    const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (validKeys.some((validKey) => validKey === event.key)) return;
    if (!/^\d$/.test(event.key)) event.preventDefault();

    const previousQuantity = product.quantity;
    const amount = event.clipboardData.getData('text/plain');

    if (amount > product.maxOrderQuantity) {
      product.quantity = product.maxOrderQuantity;
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
    } else {
      product.quantity = +amount;
    }

    const value = amount / product.subUnit.multiple;
    const isMultiplo = this.isInteger(value);
    if (!isMultiplo)
      product.quantity = Math.ceil(value) * product.subUnit.multiple;

    if (
      product.groupQuantityCount - previousQuantity + product.quantity >
      product.missingBottles
    ) {
      product.quantity =
        product.missingBottles -
        (product.groupQuantityCount - previousQuantity);
    }
    this.refreshProductsSelected();
    event.preventDefault();
  }

  isInteger(quantity: number): boolean {
    return Number.isInteger(quantity);
  }

  onImgError(event): void {
    event.target.src = this.notFoundImg;
  }

  showTooltipForThreeSeconds(tooltip) {
    if (!tooltip.isOpen()) {
      of(tooltip.open()).pipe(delay(3000)).subscribe({ next: () => tooltip.close() });
    }
  }
}
