import { CommonModule } from '@angular/common';
import { NgModule, Pipe } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { RouterModule } from '@angular/router';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';

import * as fromMaterial from '../../assets/scss/material-components';
import { RepeatOrderModalComponent } from '../pages/new-order/modals/repeat-order-modal/repeat-order-modal.component';
import { DiscountProductCardComponent } from './components/discount-product-card/discount-product-card.component';
import { ExpTimerWarnComponent } from './components/exp-timer-warn/exp-timer-warn.component';
import { KaAddToCartButtonLabelComponent } from './components/ka-add-to-cart-button-label/ka-add-to-cart-button-label.component';
import { KaAddToCartButtonComponent } from './components/ka-add-to-cart-button/ka-add-to-cart-button.component';
import { KaAvailableCreditsComponent } from './components/ka-available-credits/ka-available-credits.component';
import { KaBackButtonComponent } from './components/ka-back-button/ka-back-button.component';
import { KaBlockMessageComponent } from './components/ka-block-message/ka-block-message.component';
import { KaBottomModalHistoryOrderComponent } from './components/ka-bottom-modal-history-order/ka-bottom-modal-history-order.component';
import { KaButtonMenuComponent } from './components/ka-button-menu/ka-button-menu.component';
import { KaCategoriesCarouselComponent } from './components/ka-categories-carousel/ka-categories-carousel.component';
import { KaDeliveryAddressWidgetComponent } from './components/ka-delivery-address-widget/ka-delivery-address-widget.component';
import { KaDeliveryDetailsComponent } from './components/ka-delivery-details/ka-delivery-details.component';
import { KaMyDraftRequestBannerComponent } from './components/ka-draft-request-banner/ka-draft-request-banner.component';
import { KaDropdownComponent } from './components/ka-dropdown/ka-dropdown.component';
import { KaFiltersComponent } from './components/ka-filters/ka-filters.component';
import { KaFrontendVersionComponent } from './components/ka-frontend-version/ka-frontend-version.component';
import { KaHeaderSelectClientComponent } from './components/ka-header-select-client/ka-header-select-client.component';
import { KaImageMenuComponent } from './components/ka-image-menu/ka-image-menu.component';
import { KaInputFormComponent } from './components/ka-input-form/ka-input-form.component';
import { KaLastOrderComponent } from './components/ka-last-order/ka-last-order.component';
import { KaMenuItemsComponent } from './components/ka-menu-items/ka-menu-items.component';
import { KaMinimunPurchaseComponent } from './components/ka-minimun-purchase/ka-minimun-purchase.component';
import { KaMissingBottlesWarningComponent } from './components/ka-missing-bottles-warning/ka-missing-bottles-warning.component';
import { KaModifyProductQuantityComponent } from './components/ka-modify-product-quantity/ka-modify-product-quantity.component';
import { KaNavbarMenuMobileComponent } from './components/ka-navbar-menu-mobile/ka-navbar-menu-mobile.component';
import { KaNavbarComponent } from './components/ka-navbar/ka-navbar.component';
import { KaOrderFooterComponent } from './components/ka-order-footer/ka-order-footer.component';
import { KaOrderStatusComponent } from './components/ka-order-status/ka-order-status.component';
import { KaPacksAndDiscountsComponent } from './components/ka-packs-and-discounts/ka-packs-and-discounts.component';
import { KaPerceptionsWarningComponent } from './components/ka-perceptions-warning/ka-perceptions-warning.component';
import { KaRateExpComponent } from './components/ka-rate-exp/ka-rate-exp.component';
import { KaRightSidebarComponent } from './components/ka-right-sidebar/ka-right-sidebar.component';
import { KaSelectCountryComponent } from './components/ka-select-country/ka-select-country.component';
import { KaSelectDeliveryDateComponent } from './components/ka-select-delivery-date/ka-select-delivery-date.component';
import { KaSidebarComponent } from './components/ka-sidebar/ka-sidebar.component';
import { KaSimpleProductComponent } from './components/ka-simple-product/ka-simple-product.component';
import { KaSplittedInputComponent } from './components/ka-splitted-input/ka-splitted-input.component';
import { KaStatusPillComponent } from './components/ka-status-pill/ka-status-pill.component';
import { KaSubunitsComponent } from './components/ka-subunits/ka-subunits.component';
import { KaSuggestedOnCartCarouselComponent } from './components/ka-suggested-on-cart-carousel/ka-suggested-on-cart-carousel.component';
import { KaSuggestedOnCartComponent } from './components/ka-suggested-on-cart/ka-suggested-on-cart.component';
import { KaSuggestedProductComponent } from './components/ka-suggested-product/ka-suggested-product.component';
import { KaVerticalImageMenuComponent } from './components/ka-vertical-image-menu/ka-vertical-image-menu.component';
import { KaWeekBestsellersComponent } from './components/ka-week-bestsellers/ka-week-bestsellers.component';
import { KaWhatsappCallComponent } from './components/ka-whatsapp-call/ka-whatsapp-call.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { LoadingModalComponent } from './modals/loading-modal/loading-modal.component';
import { OtherDeviceLoginWarningComponent } from './modals/other-device-login-warning/other-device-login-warning.component';
import { PassAttempModalComponent } from './modals/pass-attemp-modal/pass-attemp-modal.component';
import { PpdBlockComponent } from './modals/ppd-block/ppd-block.component';
import { SessionExpiredModalComponent } from './modals/session-expired-modal/session-expired-modal.component';
import { TempPassExpiredModalComponent } from './modals/temporary-pass-expired-modal/temp-pass-expired-modal.component';
import { AreAllBoxesCompletedPipe } from './pipes/are-all-boxes-completed.pipe';
import { AreAllMinimumsCompletedPipe } from './pipes/are-all-minumums-completed.pipe';
import { CheckSubunitCompleteOrderPipe } from './pipes/check-subunit-complete-order.pipe';
import { EnabledNartPipe } from './pipes/enabled-nart.pipe';
import { GetScaleDiscountPricePipe } from './pipes/get-scale-discount-price.pipe';
import { GetScaleUnitPricePipe } from './pipes/get-scale-unit-price.pipe';
import { GetTotalFinalPriceCCPipe } from './pipes/get-total-final-price-cc.pipe';
import { GetTotalListPriceCCPipe } from './pipes/get-total-list-price-cc.pipe';
import { GetUnitPriceCCPipe } from './pipes/get-unit-price-cc.pipe';
import { IsEmptyObjPipe } from './pipes/is-empty-obj.pipe';
import { IsMobilePipe } from './pipes/is-mobile.pipe';
import { IsProductFrozenPipe } from './pipes/is-product-frozen.pipe';
import { IsQuantitySelectedValidPipe } from './pipes/is-quantity-selected-valid.pipe';
import { IsTheStepValidPipe } from './pipes/is-the-step-valid.pipe';
import { KaCategoriesPipe } from './pipes/ka-categories.pipe';
import { KaCreditsPipe } from './pipes/ka-credits.pipe';
import { KaDatePipe } from './pipes/ka-date.pipe';
import { KaSafeUrlPipe } from './pipes/ka-safe-url.pipe';
import { SegmentErrorsFormatPipe } from './pipes/segment-errors-format.pipe';
import { ShouldContinueButtonDisablePipe } from './pipes/should-continue-button-disable.pipe';
import { ShowAddButtonOnCardPipe } from './pipes/show-add-button-on-card.pipe';
import { ShowDiscountLabelForCCPipe } from './pipes/show-discount-label-for-cc.pipe';
import { ShowSelectButtonOnCardPipe } from './pipes/show-select-button-on-card.pipe';
import { ValidateErrorsPipe } from './pipes/validate-errors.pipe';
import { KaDiscountsApplyProducts } from './pipes/ka-discounts-apply-products.pipe';
import { KaProductCategoryLockComponent } from './components/ka-product-category-lock/ka-product-category-lock.component';
import { IsNotANumberPipe } from './pipes/is-not-a-number.pipe';
import { ShowCancelOrderButtonPipe } from './pipes/show-cancel-order-button.pipe';
import { RootImagePipe } from './pipes/root-image.pipe';
import { KaCurrencyPipe } from './pipes/ka-currency.pipe';
import { ProductImageModalComponent } from './modals/product-image-modal/product-image-modal.component';
import { KaDeleteProductComponent } from './components/ka-delete-product/ka-delete-product.component';
import { KaConfirmDeleteProductComponent } from './components/ka-confirm-delete-product/ka-confirm-delete-product.component';
import { KaFavButtonComponent } from './components/ka-fav-button/ka-fav-button.component';
import { GetDeliveryDatePipe } from './pipes/get-delivery-date.pipe';
import { ToThumbnailPipe } from './pipes/to-thumbnail.pipe';
import { DaysPipe } from './pipes/days.pipe';
import { KaOrderFilterDropdownComponent } from './components/ka-order-filter-dropdown/ka-order-filter-dropdown.component';
import { KaDraggableCarouselComponent } from './components/ka-draggable-carousel/ka-draggable-carousel.component';
import { KaCategoriesButtonComponent } from './components/ka-categories-carousel/ka-categories-button/ka-categories-button.component';
import { InvalidDetailOrderPipe } from '../pages/my-orders/pipes/invalid-detail-order.pipe';
import { KaSuggestedTotalButtonComponent } from './components/ka-suggested-total-button/ka-suggested-total-button.component';
import { IsAllSuggestedOnDraftPipe } from './pipes/is-all-suggested-on-draft.pipe';
import { ComponentsModule } from '@components/components.module';
import { ToPercentPipe } from './pipes/to-percent.pipe';
import { KaFavNotificationsComponent } from './components/ka-fav-notifications/ka-fav-notifications.component';
import { KaDiscountCarouselComponent } from './components/ka-discount-carousel/ka-discount-carousel.component';
import { ToTruncatePipe } from './pipes/truncate.pipe';
import { KaSkeletonCategoryButtonComponent } from './components/ka-categories-carousel/ka-skeleton-category-button/ka-skeleton-category-button.component';

import { ShowDeliveryDateComponent } from './components/show-delivery-date/show-delivery-date.component';
import { SelectDeliveryDateComponent } from './components/select-delivery-date/select-delivery-date.component';
import { SelectFrozenDeliveryDateComponent } from './components/select-frozen-delivery-date/select-frozen-delivery-date.component';
import { KaReturnMaterialNotificationComponent } from './components/ka-return-material-notification/ka-return-material-notification.component';
import { KaProductNotAvailableComponent } from './components/ka-product-not-available/ka-product-not-available.component';
import { IsCreditCategoryPipe } from './pipes/is-credit-category.pipe';
import { KaSuggestedViewButtonComponent } from './components/ka-suggested-view-button/ka-suggested-view-button.component';
import { IsPaymentMethodCheckedPipe } from './pipes/is-payment-method-checked.pipe';
import { ShowPaymentMethodPipe } from './pipes/show-payment-method.pipe';
import { KaCategoriesIconComponent } from './components/ka-categories-carousel/ka-categories-icon/ka-categories-icon.component';
import { NewLabelDirective } from './components/ka-categories-carousel/directives/new-label.directive';
import { FormatPeriodPipe } from '../pages/digital-promotions/combos-history-exchange/pipes/format-period.pipe';
import { PromosPanelComponent } from '../pages/digital-promotions/combos-history-exchange/components/promos-panel/promos-panel.component';
import { ShowPaymentMethodWithCreditBalancePipe } from './pipes/show-payment-method-with-credit-balance.pipe';
import { KaShowCommerceComponent } from './components/ka-show-commerce/ka-show-commerce.component';
import { KaSelectCommerceComponent } from './components/ka-select-commerce/ka-select-commerce.component';

const sharedModules: NgModule[] = [
  CommonModule,
  NgbModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CarouselModule,
  FontAwesomeModule,
  ...fromMaterial.materialComponents,
  InfiniteScrollModule,
  PdfViewerModule,
  TranslateModule.forChild({}),
  ComponentsModule,
];

const sharedComponents: any[] = [
  ProductImageModalComponent,
  KaSelectCountryComponent,
  KaImageMenuComponent,
  KaVerticalImageMenuComponent,
  KaNavbarComponent,
  KaNavbarMenuMobileComponent,
  KaSuggestedProductComponent,
  KaSelectDeliveryDateComponent,
  KaSplittedInputComponent,
  KaRateExpComponent,
  KaPacksAndDiscountsComponent,
  KaButtonMenuComponent,
  KaWeekBestsellersComponent,
  KaSimpleProductComponent,
  SessionExpiredModalComponent,
  TempPassExpiredModalComponent,
  ConfirmModalComponent,
  RepeatOrderModalComponent,
  KaLastOrderComponent,
  KaRightSidebarComponent,
  DiscountProductCardComponent,
  KaOrderFooterComponent,
  KaDeliveryDetailsComponent,
  KaSuggestedOnCartComponent,
  KaAvailableCreditsComponent,
  ExpTimerWarnComponent,
  KaSidebarComponent,
  KaMinimunPurchaseComponent,
  KaMyDraftRequestBannerComponent,
  LoadingModalComponent,
  KaMenuItemsComponent,
  KaWhatsappCallComponent,
  PassAttempModalComponent,
  KaOrderStatusComponent,
  KaDeliveryAddressWidgetComponent,
  KaStatusPillComponent,
  KaDropdownComponent,
  KaFiltersComponent,
  KaHeaderSelectClientComponent,
  KaAddToCartButtonComponent,
  KaInputFormComponent,
  KaModifyProductQuantityComponent,
  PpdBlockComponent,
  KaBlockMessageComponent,
  KaSubunitsComponent,
  KaMissingBottlesWarningComponent,
  OtherDeviceLoginWarningComponent,
  KaBottomModalHistoryOrderComponent,
  KaSuggestedOnCartCarouselComponent,
  KaPerceptionsWarningComponent,
  KaCategoriesCarouselComponent,
  KaFrontendVersionComponent,
  KaBackButtonComponent,
  KaAddToCartButtonLabelComponent,
  KaProductCategoryLockComponent,
  KaFavButtonComponent,
  KaDeleteProductComponent,
  KaConfirmDeleteProductComponent,
  KaOrderFilterDropdownComponent,
  KaDraggableCarouselComponent,
  KaCategoriesButtonComponent,
  KaSuggestedTotalButtonComponent,
  KaFavNotificationsComponent,
  KaDiscountCarouselComponent,
  KaSkeletonCategoryButtonComponent,
  KaReturnMaterialNotificationComponent,
  KaProductNotAvailableComponent,
  KaSuggestedViewButtonComponent,
  KaCategoriesIconComponent,
  PromosPanelComponent,
  KaShowCommerceComponent,
  KaSelectCommerceComponent,
];

const sharedPipes: Pipe[] = [
  RootImagePipe,
  KaCurrencyPipe,
  KaDatePipe,
  KaCreditsPipe,
  KaCategoriesPipe,
  KaCategoriesPipe,
  KaSafeUrlPipe,
  IsMobilePipe,
  GetTotalFinalPriceCCPipe,
  ShowDiscountLabelForCCPipe,
  IsProductFrozenPipe,
  CheckSubunitCompleteOrderPipe,
  GetTotalListPriceCCPipe,
  GetUnitPriceCCPipe,
  GetScaleDiscountPricePipe,
  ShowSelectButtonOnCardPipe,
  IsQuantitySelectedValidPipe,
  GetScaleUnitPricePipe,
  ShowAddButtonOnCardPipe,
  SegmentErrorsFormatPipe,
  ShouldContinueButtonDisablePipe,
  EnabledNartPipe,
  IsEmptyObjPipe,
  IsTheStepValidPipe,
  ValidateErrorsPipe,
  AreAllMinimumsCompletedPipe,
  AreAllBoxesCompletedPipe,
  KaDiscountsApplyProducts,
  IsNotANumberPipe,
  ShowCancelOrderButtonPipe,
  GetDeliveryDatePipe,
  ToThumbnailPipe,
  DaysPipe,
  InvalidDetailOrderPipe,
  IsAllSuggestedOnDraftPipe,
  ToPercentPipe,
  ToTruncatePipe,
  IsCreditCategoryPipe,
  IsPaymentMethodCheckedPipe,
  ShowPaymentMethodPipe,
  FormatPeriodPipe,
  ShowPaymentMethodWithCreditBalancePipe,
];

const sharedDirectives: any[] = [NewLabelDirective];

@NgModule({
  declarations: [sharedComponents, sharedPipes, sharedDirectives],
  imports: [
    sharedModules,
    ShowDeliveryDateComponent,
    SelectDeliveryDateComponent,
    SelectFrozenDeliveryDateComponent,
  ],
  exports: [sharedModules, sharedComponents, sharedPipes, TranslateModule],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    sharedPipes,
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
