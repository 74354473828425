
<div *ngIf="style==='GRID'"
  class="card px-4 pb-4 pt-0"
  [ngClass]="{
    home: page === PageNames.HOME,
    alternative: homeStyle === 2,
    unavailable: unavailable,
    'suggested-border': productSuggetedLabel
  }"
>
  <div class="suggested-label pt-3 pb-2" *ngIf="productSuggetedLabel && !carousel">
    <p>
      <img src="../../../../assets/svg/sugerido.svg" alt="" />
      {{ ROOT + 'SUGGESTED_PRODUCT' | translate }}
    </p>
  </div>
  <app-ka-fav-button   *ngIf="product?.isFavorite" 
    [product]="product"
    [isFavorite]="product?.isFavorite"
    class="fav-btn m-2"
  ></app-ka-fav-button>
  <div
    class="row image"
    [ngClass]="{ cursor: page !== PageNames.HOME }"
    (click)="cartService.openImage(product)"
  >
    <img
      class="image-product mw-100"
      [ngClass]="{'carousel':carousel}"
      [src]="product.image | toThumbnail"
      alt=""
      (error)="onImgError()"
    />
  </div>   
    <div
    class="product-name pt-3 pb-2"
    [ngClass]="{ home: page === PageNames.HOME }"
    style="white-space: normal"
    >
       <span>{{ product.name }}</span>
    </div>
  <div class="status-pill w-100 mt-3" *ngIf="product.relatedDiscount">
    <app-ka-status-pill
      [status]="pillMsg"
      [isDiscount]="true"
    ></app-ka-status-pill>
  </div>
    
<div class="product-unit-label mb-3 mt-1 d-flex justify-content-between align-items-center"
[ngClass]="{ home: page === 'home' }">
    <div
      class="open-modal-product-scale d-flex align-items-center"
      (click)="handleProductScale(product)"
      *ngIf="product.relatedDiscount"
    >
      <span>{{
        'NEW_ORDER.SUGGESTED_PRODUCTS.SCALE_BUTTON_MODAL' | translate
      }}</span>
      <svg>
        <use
          xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
        />
      </svg>
    </div>
  </div> 
  <div >
    <div class="not-availability">
      {{ ROOT_LANG + 'STOCK_DIGITAL.PRODUCT_NOT_AVAILABLE' | translate}}
    </div>
  </div>
</div>

<!-- ROW -->

<div *ngIf="style==='ROW'"
  class="d-none d-md-block card-row bg-white px-3"
  [ngClass]="{ unavailable: unavailable }"
>

  <div class="suggested-label pt-3 pb-2" *ngIf="productSuggetedLabel">
    <p>
      <img src="../../../../assets/svg/sugerido.svg" alt="" />
      {{ ROOT + 'SUGGESTED_PRODUCT' | translate }}
    </p>
  </div>
  <app-ka-fav-button *ngIf="product?.isFavorite" 
    [product]="product"
    [isFavorite]="product?.isFavorite"
    class="fav-btn-row"
  ></app-ka-fav-button>
  <div class="row justify-content-center align-items-center">
    <div class="col-2">

      <div class="image w-100 m-0" (click)="cartService.openImage(product)">
        <img
          class="img-fluid mw-100"
          [src]="product.image | toThumbnail"
          alt=""
          (error)="onImgError()"
        />
      </div>
    </div>
    <div class="col-4 col-sm-5 col-xl-4">
       <div class="product-data bg-white">
        <span class="product-name">{{ product.name }} </span>
      </div>

      <div class="w-100 mb-3" *ngIf="product.relatedDiscount">
        <app-ka-status-pill
          [status]="pillMsg"
          [isDiscount]="true"
        ></app-ka-status-pill>
      </div>
     
      <p class="mid-strong mt-3" *ngIf="product.suggestedProduct?.isSuggested">
        {{ 'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_QUANTITY' | translate }}
        <span>{{ product.suggestedProduct.quantity }}</span>
        <span> {{ product.unitMeasure | lowercase }}</span>
        <span *ngIf="product.suggestedProduct.quantity > 1">s</span>
      </p>
    </div>
    <div class="col-2">
      <div class="product-data text-center bg-white">
        <div
          class="product-price w-100 text-center d-flex align-items-center justify-content-center flex-column p-0" >
          
       
        <div>
    
        </div>
        </div>

        <div
          class="open-modal-product-scale w-100 d-flex flex-row align-items-center justify-content-center mt-3"
          (click)="handleProductScale(product)"
          *ngIf="product.relatedDiscount"
        >
          <span>{{
            'NEW_ORDER.SUGGESTED_PRODUCTS.SCALE_BUTTON_MODAL' | translate
          }}</span>
          <svg>
            <use
              xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="col-4 col-sm-3 col-xl-4" *ngIf="page !== 'home'">
      <div class="d-flex flex-column my-xl-0">
        
                
        <div class="not-availability">
          {{ ROOT_LANG + 'STOCK_DIGITAL.PRODUCT_NOT_AVAILABLE' | translate}}
        </div>
 
      </div>
    </div>
  </div>
</div>


<!--Movil-->
<div class="d-md-none card bg-white p-3" [ngClass]="{ unavailable: unavailable }">
  <app-ka-fav-button [product]="product" [isFavorite]="product?.isFavorite" class="fav-btn"></app-ka-fav-button>
  <div class="row justify-content-center align-items-center m-0 p-0">
      <div class="d-none d-sm-flex col-sm-4">
          <div class="image" (click)="cartService.openImage(product)">
              <img class="img-fluid mw-100" [src]="product.image | toThumbnail" alt="" (error)="onImgError()" />
          </div>
      </div>
      <div class="p-0 col-12 col-sm-8">
          <div class="d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex d-sm-none">
                  <div class="image mr-8" (click)="cartService.openImage(product)">
                      <img class="img-fluid mw-100" [src]="product.image | toThumbnail" alt="" (error)="onImgError()" />
                  </div>
              </div>
              <div>
                  <div class="w-100 mb-3" *ngIf="product.relatedDiscount">
                      <app-ka-status-pill [status]="pillMsg" [isDiscount]="true"></app-ka-status-pill>
                  </div>

                  <div class="product-data bg-white mb-3">
                      <span class="product-name">{{ product.name }} </span>
                  </div>

                  <div class="product-data bg-white mb-md-2">
                      <div class="d-flex flex-row align-items-center justify-content-between w-100">
                          <div class="d-flex flex-column align-items-start justify-content-start">
                              <div class="product-price d-flex flex-column align-items-start justify-content-start p-0 text-center">
                                  <small *ngIf="product.price.listPrice > product.price.finalPrice" class="mr-8"></small
            >
            <span></span>
          </div>
                              </div>
                              <div class="open-modal-product-scale d-flex align-items-center" (click)="handleProductScale(product)" *ngIf="product.relatedDiscount">
                                  <span>{{
            'NEW_ORDER.SUGGESTED_PRODUCTS.SCALE_BUTTON_MODAL' | translate
          }}</span>
                                  <svg>
            <use
              xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
            />
          </svg>
                              </div>
                          </div>
                        
                      </div>
                  </div>
              </div>
              <div class="pt-3 not-available" *ngIf="page !== 'home'">
                
                {{ROOT_LANG + 'STOCK_DIGITAL.PRODUCT_NOT_AVAILABLE'   | translate}}
              </div>
          </div>
      </div>
  </div>