import { Pipe, PipeTransform } from '@angular/core';
import { MissingBottlesInfo } from '../../core/models/missing-bottles-info.model';

@Pipe({
  name: 'areAllBoxesCompleted',
})
export class AreAllBoxesCompletedPipe implements PipeTransform {
  transform(products: MissingBottlesInfo[]): boolean {
    if (!products) {
      return false;
    }
    return products?.some((group) => !group.reachedLimit);
  }
}
