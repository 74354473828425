import { Component } from '@angular/core';

@Component({
  selector: 'app-ka-skeleton-category-button',
  templateUrl:'./ka-skeleton-category-button.component.html',
  styleUrls: ['./ka-skeleton-category-button.component.scss'],
})
export class KaSkeletonCategoryButtonComponent {

}
