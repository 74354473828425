import { Component, Input } from '@angular/core';
import * as CartActions from '../../../core/state/actions/cart.actions';
import { Cart } from 'src/app/core/models/cart.model';
import { Store } from '@ngrx/store';
import { Product } from 'src/app/core/models/product.model';
import { FavoritesService } from 'src/app/core/services/favorites.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ka-fav-button',
  templateUrl: './ka-fav-button.component.html',
  styleUrls: ['./ka-fav-button.component.scss'],
})
export class KaFavButtonComponent {
  @Input() isFavorite;
  @Input() product: Product;
  private subscriptions = new Subscription();

  constructor(
    private store: Store<Cart>,
    private favService: FavoritesService,
  ) {}

  clickButton() {
    if (!this.product.availability) return;
    if (this.isFavorite) {
      this.store.dispatch(
        CartActions.removeFavoriteProduct({ product: this.product }),
      );
    }
    if (!this.isFavorite) {
      this.store.dispatch(
        CartActions.addFavoriteProduct({ product: this.product }),
      );
    }
    this.isFavorite = !this.isFavorite;
    this.subscriptions.add(
      this.favService
        .getSharingFavoriteNotifications()
        .subscribe((notification) => {
          if (notification['type'] === 'ERROR') {
            this.isFavorite = false;
          }
        }),
    );
  }
}
