import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  throwError,
} from 'rxjs';

import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { Cart } from 'src/app/core/models/cart.model';
import { ProductFilter } from 'src/app/core/models/product-filter.model';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import { MyAccountService } from 'src/app/pages/my-account/services/my-account.service';
import { environment } from 'src/environments/environment';
import { DefaultImages } from '../enums/default-images';
import { DiscountCalculationType } from '../enums/discount-calculation-type';
import { DiscountTypes } from '../enums/discount-types';
import { BERespModel } from '../models/backend/BE-response.model';
import { Client } from '../models/client.model';
import { Product } from '../models/product.model';
import { UserInfo } from '../models/user-info.model';
import { ProductsCalcs } from '../utils/products-calcs';
import { ApiService } from './api.service';
import { AppUtils } from '../utils/app-utils';
import { env } from 'src/app/app.component';
import { reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductsService implements OnDestroy {
  client: Client;
  cart: Cart;
  user: UserInfo;
  credits: any = {};
  imgHost = environment.WEB_DOMAIN;
  private subscriptions = new Subscription();
  private cache: Map<number, ReplaySubject<any>> = new Map();
  private hasSuggestedProductsSubject = new BehaviorSubject<boolean>(false);
  hasSuggestedProducts$ = this.hasSuggestedProductsSubject.asObservable();

  constructor(
    private store: Store<{ client: Client; cart: Cart; user: UserInfo }>,
    private apiSrv: ApiService,
    private myAccountService: MyAccountService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  getSuggestedProducts(
    offset = 0,
    ngxSpinner: boolean,
    filtered = false,
    showError = false,
  ): Observable<BERespModel> {
    const queryParams = this.apiSrv.generateQueryParams({
      limit: this.getProductsPerPagePortfolio(),
      offset,
    });

    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/suggestedproducts${queryParams}`,
          EndpointsCodes.GET_SUGGESTED_PROD,
          {
            ngxSpinner,
            showError,
          },
        )
        .subscribe(
          (res: BERespModel) => {
            if (!res.data) {
              this.hasSuggestedProductsSubject.next(false);
              throwError({});
              return;
            }
            res.data.suggestedProducts = res.data.suggestedProducts?.map(
              (product) => {
                return {
                  ...product,
                  image: `${this.imgHost}${
                    product.image || DefaultImages.PRODUCT
                  }`,
                  quantitySelected:
                    product.suggestedProduct?.quantity || product.quantity,
                  price: {
                    ...product.price,
                    finalPrice: parseFloat(product.price?.finalPrice),
                    listPrice: ProductsCalcs.getItemFullListPrice(product),
                  },
                };
              },
            );
            const hasSuggestedProducts =
              res.data.suggestedProducts &&
              res.data.suggestedProducts.length > 0;
            this.hasSuggestedProductsSubject.next(hasSuggestedProducts);
            obs.next(res);
          },
          (err) => {
            if (err) {
              this.hasSuggestedProductsSubject.next(false);
            }
            obs.error(err);
          },
          () => obs.complete(),
        );
    });
  }

  getDiscounts(offset = 0): Observable<any> {
    const paramsObj = {
      limit: this.getProductsPerPagePortfolio(),
      offset,
    };
    const queryParams = this.apiSrv.generateQueryParams(paramsObj);

    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/discountsportfolio${queryParams}`,
          EndpointsCodes.GET_DISCOUNTS_PROD,
          { ngxSpinner: false, customSpinner: true },
        )
        .subscribe(
          (res: BERespModel) => {
            // TO-DO quitar esto y enviar param discountsOnly cuando se agregue al endpoint
            res.data = res.data
              .filter((productOrDiscount) => productOrDiscount.discountId)
              .map((discount) => {
                const discountType = this.generateDiscountType(discount);
                return {
                  ...discount,
                  discountType,
                  image: `${this.imgHost}${
                    discount.image || DefaultImages.DISCOUNT
                  }`,
                  quantitySelected: 1,
                  requirements: this.parseGetProductsPackResp(
                    discountType,
                    discount.requirements,
                    discount.calculationType,
                  ),
                };
              });
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  private generateDiscountType(discount): DiscountTypes {
    if (discount.discountType !== DiscountTypes.SCALE)
      return discount.discountType;
    return discount.requirements?.productId
      ? DiscountTypes.UNIT_SCALE
      : DiscountTypes.PACK_SCALE;
  }

  private parseGetProductsPackResp(
    discountType,
    requirements,
    calculationType,
  ): any {
    const addsMinScalesUnit = () => {
      let fakeMinScaleUnit;
      const minorScale = requirements.scales.reduce( (acum , scale) => {
        if (acum === undefined) {
          acum = scale;
        }

        if ( scale.min < acum.min ) {
          acum = scale;
        }

        return acum;
      });

      const firstScaleUnit = { ...minorScale };
      if (firstScaleUnit.min != 1) {
        fakeMinScaleUnit = {
          price: [],
          min: 1,
          max: firstScaleUnit.min - 1,
          reward: 0,
        };
        fakeMinScaleUnit.price = {
          ...firstScaleUnit.price,
          finalPrice: firstScaleUnit.price.listPrice,
          priceBySubUnit:
            firstScaleUnit.price.listPrice / firstScaleUnit.subUnitQuantity,
        };
      }
      return fakeMinScaleUnit
        ? [fakeMinScaleUnit, ...requirements.scales]
        : requirements.scales;
    };
    const addsMinScalesPack = () => {
      let fakeMinScale;
      const minorScale = requirements.scales.reduce( (acum , scale) => {
        if (acum === undefined) {
          acum = scale;
        }

        if ( scale.min < acum.min ) {
          acum = scale;
        }

        return acum;
      });

      const firstScale = { ...minorScale };
      if (firstScale.min != 1) {
        fakeMinScale = {
          products: [],
          min: 1,
          max: firstScale.min - 1,
          reward: 0,
        };
        fakeMinScale.products = firstScale.products.map((product) => {
          return {
            ...product,
            price: { 
              ...product.price,
              finalPrice: product.price.listPrice,
              priceBySubUnit: product.price.finalPriceWithoutDiscount / product.subUnitQuantity },
          };
        });
      }
      return fakeMinScale
        ? [fakeMinScale, ...requirements.scales]
        : requirements.scales;
    };

    let factor;
    switch (discountType) {
      case DiscountTypes.CLOSED:
        requirements.forEach((product) => {
          product.price.listPrice = ProductsCalcs.getItemFullListPrice(product);
        });
        return requirements;
      case DiscountTypes.OPEN:
        requirements.forEach((requirement) => {
          const products = requirement.products
            ? requirement.products
            : [requirement];
          products.forEach((product) => {
            product.price.listPrice =
              ProductsCalcs.getItemFullListPrice(product);
          });
        });
        return requirements;
      case DiscountTypes.UNIT_SCALE:
        factor = DiscountCalculationType.AMOUNT === calculationType ? 1 : 100;
        requirements.scales = AppUtils.orderBy(
          requirements.scales,
          [(scale: any) => scale.min],
          ['asc'],
        );
        requirements.scales.forEach((product) => {
          product.reward.value = parseFloat(
            (product.reward.value * factor).toFixed(1),
          );
          product.price.listPrice = ProductsCalcs.getItemFullListPrice(product);
        });

        requirements.scales = addsMinScalesUnit();

        return requirements;
      case DiscountTypes.PACK_SCALE:
        factor =
          DiscountCalculationType.AMOUNT === calculationType ||
          DiscountCalculationType.FIXED === calculationType
            ? 1
            : 100;
        requirements.scales = AppUtils.orderBy(
          requirements.scales,
          [(scale: any) => scale.min],
          ['desc'],
        );
        requirements.scales.forEach((scale) => {
          scale.reward.value = parseFloat(
            (scale.reward.value * factor).toFixed(1),
          );
          scale.products.forEach((product) => {
            product.price.listPrice =
              ProductsCalcs.getItemFullListPrice(product);
          });
        });

        requirements.scales = addsMinScalesPack();

        return requirements;
      default:
        return requirements;
    }
  }

  getPortfolioWithDiscountByClient(
    ngxSpinner: boolean,
    filters?: ProductFilter,
    offset = 0,
  ): Observable<BERespModel> {
    const paramsObj = {
      limit: this.getProductsPerPagePortfolio(),
      offset,
      ...filters,
    };
    const queryParams = this.apiSrv.generateQueryParams(paramsObj);

    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/discountsportfolio${queryParams}`,
          EndpointsCodes.GET_PORTFOLIO_PROD,
          { ngxSpinner },
        )
        .subscribe(
          (res: BERespModel) => {
            res.data = res.data.map((product) => {
              if (product.discountId) {
                const discountType = this.generateDiscountType(product);
                return {
                  ...product,
                  discountType,
                  image: `${this.imgHost}${
                    product.image || DefaultImages.DISCOUNT
                  }`,
                  quantitySelected: 1,
                  requirements: this.parseGetProductsPackResp(
                    discountType,
                    product.requirements,
                    product.calculationType,
                  ),
                };
              } else {
                return {
                  ...product,
                  image: `${this.imgHost}${
                    product.image || DefaultImages.PRODUCT
                  }`,
                  quantitySelected: 1,
                  price: {
                    ...product.price,
                    finalPrice: parseFloat(product.price?.finalPrice),
                    listPrice: ProductsCalcs.getItemFullListPrice(product),
                  },
                };
              }
            });
            if (filters?.text) {
              this.pushGoogleTag('search', filters, res);
            }
            if (this.haveFilterAplies(filters)) {
              this.pushGoogleTagFilters('filter', filters, res);
            }

            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getPortfolioFilters(filters?: ProductFilter): Observable<BERespModel> {
    const queryParams = this.apiSrv.generateQueryParams({ ...filters });

    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/filter/portfolio${queryParams}`,
          EndpointsCodes.GET_PORTFOLIO_FILTERS,
          { showError: false },
        )
        .subscribe(
          (res) => {
            res.data.categories = res.data?.categories?.sort();
            res.data.packages = res.data?.packages?.sort();
            res.data.brands = res.data?.brands?.sort();
            res.data.sizes = this.orderProductSizes(res.data.sizes);
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getProductsDiscounts(products: any[]): Observable<any> {
    return new Observable((obs) => {
      const partialOrderRQItems = products.map((product) => {
        if (product?.isBom)
          return {
            productId: product.productId,
            quantity: product.quantity,
            erpMeasureUnitId:
              product?.subunitSelected || product?.erpMeasureUnitId,
            isBom: true,
          };
        return {
          productId: product.productId,
          quantity: product.quantity,
          erpMeasureUnitId:
            product?.subunitSelected || product?.erpMeasureUnitId,
        };
      });
      const partialOrderRQ = {
        items: partialOrderRQItems,
        orderId: this.cart.orderId,
      };

      this.apiSrv
        .post(
          `clients/${this.client.clientId}/order`,
          EndpointsCodes.POST_PARTIAL_ORDER,
          partialOrderRQ,
          {},
        )
        .subscribe(
          (res: BERespModel) => {
            this.processDiscounts(res, products, !this.cart.orderId);
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getRepeatedOrder(
    orderId: number,
    deliveryDate: string | Date,
    deliveryDateFrozen: string | Date,
  ): Observable<any> {
    return new Observable((obs) => {
      const data = {
        deliveryDate,
        deliveryDateFrozen,
      };

      this.apiSrv
        .post(
          `clients/${this.client.clientId}/order/${orderId}/repeat`,
          EndpointsCodes.POST_REPEAT_ORDER,
          data,
          {},
        )
        .subscribe(
          (res: BERespModel) => {
            this.processDiscounts(res, res.data.calculatedItems, true);
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  private processDiscounts(
    res: BERespModel,
    products: any,
    notifyCreationGtm: boolean,
  ) {
    if (notifyCreationGtm) {
      this.gtmService.pushTag({
        event: 'createOrder',
        orderId: res.data?.orderId,
      });
    }
    const productsInserted = this.getProductsInserted([...products]);
    if (productsInserted?.length) {
      this.gtmService.pushTag({
        event: 'addProducts',
        productType: this.getProductType(productsInserted),
        products: productsInserted,
        orderId: res.data?.orderId,
      });
    }

    res?.data?.calculatedItems.forEach((product) => {
      const listPrice = parseFloat(product?.totals?.listPrice || 0);
      const shipping = parseFloat(product?.totals?.shippingPrice || 0);
      product.totals.listPrice = listPrice + shipping;
      product.totals.finalPrice = Math.max(product.totals.finalPrice, 0);
      product.price.priceBySubUnit = Math.max(product.price.priceBySubUnit, 0);
    });

    if (this.client.data?.credits?.length > 0) {
      this.myAccountService.getCurrentCartCredits(res.data.orderId).subscribe(
        (resp) => {
          this.credits = resp;
          this.store.dispatch(
            CartActions.updateCartCredits({
              credits: this.credits.paymentHandlerResult,
            }),
          );
        },
        () => {
          this.store.dispatch(CartActions.updateCartCredits({ credits: [] }));
        },
      );
    }
  }

  private getProductsInserted(serviceProducts: Product[]): Product[] {
    const backupProducts = this.cart.backupProducts;
    const diffProducts = serviceProducts.map((srvProduct) => {
      const prevProduct = backupProducts.find(
        (bkpProd) => srvProduct.productId === bkpProd.productId,
      );
      if (!prevProduct) return srvProduct; //if the product doesn't exist, it means is a new product
      return {
        ...srvProduct,
        quantity: srvProduct.quantity - prevProduct.quantity,
      };
    });
    return diffProducts.filter((diffProd) => diffProd.quantity > 0);
  }

  private getProductType(productsInserted: Product[]): string {
    if (!productsInserted[0].suggestedProduct) return 'DISCOUNT_PRODUCTS';
    return productsInserted[0].suggestedProduct.isSuggested
      ? 'SUGGESTED_PRODUCTS'
      : 'PORTFOLIO_PRODUCTS';
  }

  private orderProductSizes(sizes: string[]): string[] {
    if (!sizes) return sizes;
    let mlProducts = sizes.filter(
      (size) => parseFloat(size.split(' ')[0]) > 100,
    );
    let lProducts = sizes.filter(
      (size) => parseFloat(size.split(' ')[0]) < 100,
    );
    mlProducts = mlProducts?.sort(compareSizes);
    lProducts = lProducts?.sort(compareSizes);

    function compareSizes(sizeA, sizeB): number {
      const numA = parseFloat(sizeA.split(' ')[0]);
      const numB = parseFloat(sizeB.split(' ')[0]);
      if (numA < numB) return -1;
      if (numA > numB) return 1;
      return 0;
    }
    return [...mlProducts, ...lProducts];
  }

  getDiscountCalculation(
    discountId: number,
    products: Product[],
  ): Observable<any> {
    const requestBody = {
      items: products.map((product) => ({
        productId: product.productId,
        quantity: product.quantitySelected,
      })),
    };
    return new Observable((obs) => {
      this.apiSrv
        .post(
          `clients/${this.client.clientId}/discounts/${discountId}`,
          EndpointsCodes.GET_DISCOUNT_CALCULATION,
          requestBody,
          {},
        )
        .subscribe(
          (res: BERespModel) => obs.next(res),
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getDiscount(
    ngxSpinner: boolean,
    erpDiscountId: number,
  ): Observable<BERespModel> {
    if (!this.cache.has(erpDiscountId)) {
      const replaySubject = new ReplaySubject<any>(1);
      this.cache.set(erpDiscountId, replaySubject);
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/discounts/${erpDiscountId}`,
          EndpointsCodes.GET_PORTFOLIO_PROD,
          { ngxSpinner },
        )
        .subscribe(
          (res: BERespModel) => {
            const discount = res.data;
            const discountType = this.generateDiscountType(discount);
            res.data = {
              ...discount,
              discountType,
              image: `${this.imgHost}${
                discount.image || DefaultImages.DISCOUNT
              }`,
              quantitySelected: 1,
              requirements: this.parseGetProductsPackResp(
                discountType,
                discount.requirements,
                discount.calculationType,
              ),
            };
            replaySubject.next(res.data);
          },
          (err) => {
            replaySubject.error(err);
            this.cache.delete(erpDiscountId);
          },
          () => replaySubject.complete(),
        );
    }
    return this.cache.get(erpDiscountId).asObservable();
  }

  getMissingBottlesInformation(): Observable<any> {
    const requestBody = {
      items: this.cart.products.map((product) => ({
        productId: product.productId,
        quantity: product.quantitySelected || product.quantity,
        erpMeasureUnitId: product.subunitSelected || product.erpMeasureUnitId,
        size: product.size,
        package: product.package,
      })),
    };
    return new Observable((obs) => {
      this.apiSrv
        .post(
          `clients/${this.client.clientId}/missingsubunitproducts`,
          EndpointsCodes.GET_MISSING_BOTTLES_INFORMATION,
          requestBody,
          {},
        )
        .subscribe(
          (res: BERespModel) => obs.next(res),
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getProductsPerPagePortfolio() {
    const fixedProductPagination = this.getFixPaginationNumber();
    return (
      env.getConfigByCountry().productsPerPagePortfolio - fixedProductPagination
    );
  }

  private getFixPaginationNumber(): number {
    const MAX_WIDTH_RESOLUTION_BREAKPOINT = 1660;
    const pageWidth = window.innerWidth;
    return pageWidth >= MAX_WIDTH_RESOLUTION_BREAKPOINT ? 2 : 0;
  }

  private haveFilterAplies(filters): boolean {
    return (
      filters?.brand ||
      filters?.category ||
      filters?.isDiscount ||
      filters?.isFavorite ||
      filters?.package ||
      filters?.returnability ||
      filters?.size
    );
  }

  private pushGoogleTag(
    event: string,
    productFilters: ProductFilter,
    res: BERespModel,
  ): void {
    this.gtmService.pushTag({
      event,
      term: productFilters.text,
      cant_result: res.data.length,
      page_result: res?.pagination?.currentpage,
    });
  }

  private pushGoogleTagFilters(
    event: string,
    productFilters: ProductFilter,
    res: BERespModel,
  ): void {
    this.gtmService.pushTag({
      event,
      promotion_filter: productFilters.isDiscount ? 'yes' : 'no',
      returnable_filter: productFilters.returnability ? 'yes' : 'no',
      favorites_filter: productFilters.isFavorite ? 'yes' : 'no',
      category_filter: productFilters.category,
      brand_filter: productFilters.brand,
      packing_filter: productFilters.package,
      size_filter: productFilters.size,
      cant_result: res?.data?.length,
      page_result: res?.pagination?.currentpage,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
